/** @jsx jsx */
import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useState } from 'react'
import { Flex, jsx } from 'theme-ui'
import Layout from '../layouts/index'
import Sidebar from '../layouts/Sidebar'
import {
  Aside,
  CaptionCard,
  ComponentUsage,
  ContentCard,
  Example,
  DetailedCard,
  Metadata,
  PreviewCard,
  ProductLockup,
  Tab,
  Tabs
} from '../components/mdx/index'
import TableOfContents from '../components/mdx/TableOfContents'

const MDXTemplate = ({ data, pageContext }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const MDXshortcodes = {
    Aside,
    CaptionCard,
    ContentCard,
    DetailedCard,
    Example,
    PreviewCard,
    ProductLockup,
    Tab,
    Tabs
  }

  if (data.mdx.headings.filter(heading => heading.depth === 1).length > 1) {
    console.warn('Each page should have only 1 <h1>.')
  }
  const title =
    data.mdx.headings.find(heading => heading.depth === 1)?.value ||
    pageContext.frontmatter.title

  return (
    <Layout
      title={title}
      description={pageContext.frontmatter.description}
      sidebarOpen={sidebarOpen}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          minHeight: 0
        }}
      >
        <Sidebar
          data={data}
          pageContext={pageContext}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div
          sx={{
            // Prevent content overflowing parent such as large table
            minWidth: 0,
            flex: 5,
            px: [null, null, 4],
            display: [
              sidebarOpen ? 'none' : 'initial',
              sidebarOpen ? 'none' : 'initial',
              'initial'
            ]
          }}
        >
          <div
            id='content-top'
            sx={{
              p: {
                maxWidth: '72ch'
              }
            }}
          >
            {/* <Metadata frontmatter={pageContext.frontmatter} title={title} /> */}
            <Metadata frontmatter='' title={title} />
            <MDXProvider components={MDXshortcodes}>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>
            <ComponentUsage frontmatter={pageContext.frontmatter} />
          </div>
        </div>
        <div
          sx={{
            flex: 1
          }}
        >
          {/* <Metadata frontmatter={pageContext.frontmatter} title={title} /> */}
          <Metadata frontmatter={pageContext.frontmatter} title='' />
          <TableOfContents headings={data.mdx.headings} />
        </div>
      </Flex>
    </Layout>
  )
}

export const query = graphql`
  query QuerySidebar($base: String!, $slug: String!) {
    allMdx(filter: { slug: { regex: $base } }, sort: { fields: slug }) {
      nodes {
        id
        frontmatter {
          title
          order
        }
        slug
      }
    }
    mdx(slug: { eq: $slug }) {
      body
      headings {
        depth
        value
      }
    }
  }
`

export default MDXTemplate
