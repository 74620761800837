/** @jsx jsx */
import { Icon } from '@cloudflare/component-icon'
import { Link } from 'gatsby'
import React, { Fragment } from 'react'
import { Button, jsx } from 'theme-ui'

const Sidebar = ({ data, pageContext, sidebarOpen, setSidebarOpen }) => {
  const tree = []
  const pagesByPath = {}
  const map = {}

  const pages = data.allMdx.nodes
    .map((page, i) => {
      pagesByPath[page.slug] = page
      map[page.id] = i
      return { ...page, depth: page.slug.split('/').length - 2 }
    })
    .map(page => {
      const parentPath = page.slug.replace(/\/$/, '').replace(/\/[^/]*$/, '')
      if (!parentPath) return page
      const parentNode = pagesByPath[`${parentPath}/`]
      if (!parentNode) return page
      return { ...page, parentId: pagesByPath[`${parentPath}/`].id }
    })

  pages.forEach(page => {
    if (page.depth > 0) {
      if (page.parentId) {
        if (!pages[map[page.parentId]].children)
          pages[map[page.parentId]].children = []
        pages[map[page.parentId]].children.push(page)
      }
    } else {
      tree.push(page)
    }
  })

  if (!tree[0].children) {
    return null
  }

  const breadcrumbs = []

  const loop = node => {
    if (Array.isArray(node)) {
      return node
        .sort((a, b) => {
          const returnOrder = order => {
            if (order || order === 0) {
              return order
            } else {
              return 10e6
            }
          }

          return (
            returnOrder(a.frontmatter.order) - returnOrder(b.frontmatter.order)
          )
        })
        .map(n => {
          if (pageContext.slug.startsWith(n.slug)) {
            breadcrumbs.push(n)
          }

          if (n.depth === 0) {
            return (
              n.children && (
                <ul key={n.slug} sx={{ listStyle: 'none', m: 0, p: 0 }}>
                  {loop(n.children)}
                </ul>
              )
            )
          } else {
            return (
              <li key={n.slug} sx={{ mb: 1 }}>
                {n.depth !== 0 && (
                  <div sx={{ mb: 1, ml: (n.depth - 1) * 2 }}>
                    <Link
                      to={`/${n.slug}`}
                      activeClassName='active'
                      sx={{ variant: 'links.navigation' }}
                    >
                      <span>{n.frontmatter.title}</span>
                    </Link>
                  </div>
                )}
                {n.children && (
                  <ul sx={{ listStyle: 'none', m: 0, p: 0 }}>
                    {loop(n.children)}
                  </ul>
                )}
              </li>
            )
          }
        })
    }
  }
  const navigation = loop(tree)

  return (
    <React.Fragment>
      <div
        sx={{
          display: ['flex', 'flex', 'none'],
          flexDirection: 'row',
          justifyContent: 'space-between',
          overflowX: 'scroll',
          py: 2
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflowX: 'scroll'
          }}
        >
          {breadcrumbs.map((bread, index) => {
            if (bread && bread.slug && bread.frontmatter.title) {
              return (
                <Fragment key={index}>
                  {index === breadcrumbs.length - 1 ? (
                    <Button
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                      children={bread.frontmatter.title}
                      variant='sansOutline'
                      sx={{ whiteSpace: 'nowrap' }}
                    />
                  ) : (
                    <Link
                      to={`/${bread.slug}`}
                      children={bread.frontmatter.title}
                      sx={{ variant: 'links.plain', whiteSpace: 'nowrap' }}
                    />
                  )}
                  {index < breadcrumbs.length - 1 && (
                    <div sx={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type='right' size={16} sx={{ mx: 1 }} />
                    </div>
                  )}
                </Fragment>
              )
            } else {
              console.warn('Parent level probably missing an index.mdx?')
              return null
            }
          })}
        </div>
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: '4px'
          }}
        >
          <Button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            variant='sansOutline'
            sx={{ background: 'transparent' }}
          >
            <Icon
              type='caret-down'
              size={16}
              sx={{
                ml: 2,
                transform: sidebarOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                transitionDuration: '0.2s'
              }}
            />
          </Button>
        </div>
      </div>
      <nav
        sx={{
          display: [
            sidebarOpen ? 'block' : 'none',
            sidebarOpen ? 'block' : 'none',
            'block'
          ],
          flex: [null, null, '0'],
          flexBasis: [null, null, '240px'],
          alignSelf: [null, null, 'flex-start'],
          position: 'sticky',
          top: 76,
          overflowX: 'hidden',
          overflowY: 'scroll',
          pt: [null, null, 3],
          pb: [3, 3, null],
          '::-webkit-scrollbar': {
            width: '8px'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: `decorative`,
            borderRadius: '8px'
          },
          // Small screen version
          left: [0, 0, null],
          right: [0, 0, null],
          ml: ['-8px', '-8px', null],
          maxHeight: [
            'calc(100vh - 76px - 27px)',
            'calc(100vh - 76px - 27px)',
            'calc(100vh - 76px)'
          ],
          backgroundColor: ['background', 'background', 'initial']
        }}
      >
        {navigation}
      </nav>
    </React.Fragment>
  )
}

export default Sidebar
