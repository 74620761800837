/** @jsx jsx */
import slugify from 'slugify'
import { jsx } from 'theme-ui'

const TableOfContents = ({ headings }) => {
  if (headings.filter(heading => heading.depth !== 1).length <= 1) {
    return null
  }

  return (
    <ul
      sx={{
        borderTop: '1px solid',
        borderColor: 'decorative',
        listStyle: 'none',
        m: 0,
        p: 0,
        alignSelf: 'flex-start',
        position: 'sticky',
        top: 76 - 12,
        flex: 1,
        pt: 4,
        mt: 4,
        fontSize: 2
      }}
    >
      <li sx={{ mb: 1 }}>
        <a href='#content-top' sx={{ variant: 'links.basic' }}>
          ↑ Top
        </a>
      </li>
      {headings
        .filter(heading => heading.depth !== 1)
        .map((heading, i) => (
          <li
            key={i}
            sx={{
              ml: (heading.depth - 2) * 2,
              mb: 1
            }}
          >
            <a
              href={`#${slugify(heading.value, { lower: true })}`}
              sx={{ variant: 'links.basic' }}
            >
              {heading.value}
            </a>
          </li>
        ))}
    </ul>
  )
}

export default TableOfContents
